<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>list</md-icon>
            <h3 class="pl-2 text-uppercase">Create List/Group</h3>
          </div>
          <div class="flex">
           <md-button class="outline-gray-400 rounded ">
             <md-icon>date_range</md-icon> 28 March, 2020
           </md-button>
           <md-icon>remove</md-icon>
           <md-button class="outline-gray-400 rounded ">
             <md-icon>date_range</md-icon> 28 March, 2020
           </md-button>
            <md-button
              class="bg-green ml-3 text-white rounded-md text-uppercase"
              @click="dialog(true)"
            >
              <md-icon class="text-white">add</md-icon> Create New
            </md-button>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
          <SelectComponent placeholder="Select one" class="w-64 m-2" />
          <div class="align-center flex">
            <SwitchComponent :items="[{id: 1, name: 'Single'}, {id: 2, name: 'Group'}]"/>
          </div>
        </div>
        <md-divider></md-divider>
        <Table :items="communications" @onSelect="onSelect" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-label="Name" class="text-victoria">{{ item.name }}</md-table-cell>
            <md-table-cell md-label="DESIGNATION" class="text-victoria">{{ item.designation }}</md-table-cell>
            <md-table-cell md-label="BRANCH" class="text-victoria">{{ item.branch }}</md-table-cell>
            <md-table-cell md-label="COURSE" class="text-victoria">{{ item.course }}</md-table-cell>
            <md-table-cell md-label="EMAIL ADDRESS">{{ item.emailAddress }}</md-table-cell>
            <md-table-cell md-label="CONTACT">{{ item.contact }}</md-table-cell>
            <md-table-cell md-label="ADDED BY">{{ item.addedBy }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">edit</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">delete</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
    <Dialog>
      <CreateNewNotice />
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    SelectComponent,
    SwitchComponent,
    } from "@/components";
import CreateNewNotice from "@/components/molecule/communication/CreateNewNotice";
import data from "@/data/communication/data";
import { mapMutations } from "vuex";

export default {
    components: {
        Card,
        Table,
        CardBody,
        Dialog,
        CreateNewNotice,
        SelectComponent,
        SwitchComponent
  },
  data() {
      return {
          communications: data.communications
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onSelect() {

    }
  },
}
</script>